import "./../css/Cart.css";
import CartController from "./../controllers/CartController"; 
import "./../css/widget/Login.css";
import "./../css/widget/Billing.css";
import "./../css/widget/CartCard.css";
import "./../css/widget/CartComponent.css";


const Cart = () => {

  return <CartController></CartController>

};
  
export default Cart;
  