import { useParams } from "react-router-dom";
import PaymentController from "../controllers/PaymentController";
import "./../css/StoreItemDetail.css";

const StoreItemDetail = () => {
  const params = useParams();
  return <PaymentController {...params}></PaymentController>;
};

export default StoreItemDetail;
