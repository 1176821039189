import { useParams } from "react-router-dom";
import StoreController from "../controllers/StoreController";
import "./../css/Store.css";  

const Store = () => { 
    const params = useParams();
    return (
        <StoreController {...params}></StoreController>
    );
  };
  
  export default Store;
  