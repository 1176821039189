import React, { useEffect, useContext } from 'react';
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from './Footer';
import Header from './Header';
import SubscribeModal from '../widgets/SubscribeModal';

import { AppContext } from "./../config/common";
import { useSearchParams } from 'react-router-dom';

import { SiteService } from "./../services/site";
import Catalog from '../Catalog';

const Layout = () => {
  
  const [searchParams] = useSearchParams();
  const {settings, updateSetting,socialIcons,updateSocialIcons,hideSearchForm } = useContext(AppContext);
  
  const fetchSiteSettings = async () => {
    if(!settings||!settings.createdAt){
      const sitedata = await SiteService.fetchSiteSettings();
      // debug
      // sitedata.show_auth=true
      updateSetting(sitedata);   
      await Catalog.init();
      Catalog.isLoad=true; 
    }
  };
 
  const fetchSocialIcons = async () => {
    if(!socialIcons||!socialIcons.createdAt){
      const icondata = await SiteService.fetchSocialIcons();    
      updateSocialIcons(icondata);
    }
  };
  let lc =localStorage.getItem("lc")||"en";
  if(searchParams.get('lc')){
    lc=searchParams.get('lc')
    localStorage.setItem("lc",lc)
  }
  Catalog.LC=lc;
  const dir = lc ==="ar"?"rtl":"ltr"; 
  
  useEffect(() => {
    fetchSiteSettings()
    fetchSocialIcons()
  })

  return (
    <> 
      <Helmet>
        <html lang={lc} dir={dir} />
      </Helmet>
      <Header hideSearchForm={hideSearchForm} />
      <div className="body-container">
        <div className="container">
          <Outlet />
        </div>
      </div>
      <Footer></Footer>
      <SubscribeModal></SubscribeModal>
    </>

  )
};

export default Layout;
