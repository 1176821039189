import { useParams } from "react-router-dom";
import StoreItemDetailController from "../controllers/StoreItemDetailController";
import "./../css/StoreItemDetail.css";
import "./../css/Detail.css";


const StoreItemDetail = ({ hideSearchForm  }) => {
  const params = useParams();
  
  return <StoreItemDetailController  hideSearchForm ={hideSearchForm}  {...params}></StoreItemDetailController>;
};

export default StoreItemDetail;
