import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, Card,Button, Alert,Form } from "react-bootstrap";
import facebook1 from "./../images/facebook-icon.svg";
import instagram1 from "./../images/instagram-icon.svg";
import outline1 from "./../images/outline-icon.svg";
import youtube1 from "./../images/youtube-icon.svg"; 
import linkedin1 from "./../images/linkedin-icon.svg";
  
import FormField from "../widgets/FormField";

import noimage from "./../images/dailytime-noimg.png";

import { dateFormat,AppContext } from "./../config/common"; 

import { NewsService } from "./../services/news"; 
import { SiteService } from "./../services/site";
import Catalog from "../Catalog";
class Footer extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      news: [],
      categorys:[],  
      formData: {
        name: null,
        email: null,
      },
      errorData: {
        name: null,
        email: null,
      },
      subscriptionerror:null,
      subscriptionsuccess:null,
    };
  }
  componentDidMount() {
    this.fetchRecentPost();
    this.fetchCategory()  
  }  
  fetchRecentPost = async () => {
    const news = await NewsService.fetchRecentPost();
    this.setState({ news });
  };
  fetchCategory = async () => {
    const categoryslist = await NewsService.fetchCategory();
    let categorys=[];
    for (const idx in categoryslist) {
      if (Object.hasOwnProperty.call(categoryslist, idx)) {
        let cat = categoryslist[idx];
        cat.post_count=await NewsService.fetchPostCountByCategory(cat.slug);
        categorys.push(cat);
      }
    }
    this.setState({ categorys });
  };




  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = this.state.formData;
    let errorData = {};
    let isInValid = false;
    for (const name in formData) {
      const valid= Catalog.validate(name,formData[name]);
      if(valid&&!valid.valid){
          errorData[name] = valid.message;
          isInValid = true;
      } 
    }
    let subscriptionerror = null;
    this.setState({ errorData, subscriptionerror });
    if (!isInValid) {
      const ndate = new Date();
      const userid =
        formData.name
          .toLowerCase()
          .replace(/[^\w ]+/g, "")
          .replace(/ +/g, "-") +
        "" +
        ndate.getTime();
      const subscription = await SiteService.subscribeNewsletter({
        name: formData.name,
        first_name: formData.name,
        email: formData.email,
        username: userid,
        password: ndate.getTime().toString(),
        role: "subscriber",
      });

      const session = window.localStorage.getItem("dailylawtimes");
      let num = 1;
      if (session && session !== "") {
        try {
          let sessionData = JSON.parse(session);
          num = parseInt((sessionData.visit ?? 0).toString()) + num;
        } catch (error) {}
      }
      if (subscription.user) {
        window.localStorage.setItem(
          "dailylawtimes",
          JSON.stringify({
            subscription: subscription,
            visit: num,
          })
        ); 

        formData = {
          name: null,
          email: null,
        };
        errorData = {
          name: null,
          email: null,
        };
        const subscriptionsuccess=Catalog.Strings.subscription.success
        this.setState({  formData, errorData ,subscriptionsuccess});
      } else {
        if (subscription.error) {
          subscriptionerror = subscription.error.message;
          this.setState({ subscriptionerror });
        }
        window.localStorage.setItem(
          "dailylawtimes",
          JSON.stringify({
            subscription: null,
            visit: num,
          })
        );
      }
    }
    return !isInValid;
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const formData = {
      ...this.state.formData,
      [name]: value,
    };
    this.setState({ formData });

    const valid= Catalog.validate(name,value);
    if(valid&&!valid.valid){ 
      const errorData = {
        ...this.state.errorData,
        [name]: valid.message,
      };
      this.setState({ errorData });
    } else{
      const errorData = {
        ...this.state.errorData,
        [name]: null,
      };
      this.setState({ errorData });
    } 
     
  };
  render() {
    const { settings,socialIcons } = this.context; 
    return (
      <>
        <div className="footer-wrap">
          <div className="container">
            <div className="row ftr-nav">
              <div className="col-md-3   social-ul">
                <div className=" ftr-col-txt"> 
                {
                    (settings.footer_description ?? "").split("\n").map((item, k, arr) => (
                      <>
                        <p key={k}>{item}</p>
                        {k === arr.length - 2 && (
                          <>
                            <p>Email: <a href={`mailto:${settings.email}`}>{settings.email}</a></p>
                            <p>Sales: <a href={`tel:${settings.phone.trim()}`}>{settings.phone}</a></p>
                          </>
                        )}
                      </>
                    ))
                  }
                </div>
                <Navbar expand="lg" className="bg-body-tertiary">
                  <Nav className=" ">
                    {
                      socialIcons?.facebook?( 
                        <Nav.Link href={socialIcons?.facebook??"#"}>
                          <img src={facebook1} alt="facebook" />
                        </Nav.Link>
                      ):(<></>)
                    }
                    {
                      socialIcons?.instagram?( 
                        <Nav.Link href={socialIcons?.instagram??"#"}>
                          <img src={instagram1} alt="instagram" />
                        </Nav.Link>
                      ):(<></>)
                    }
                    {
                      socialIcons?.twitter?( 
                        <Nav.Link href={socialIcons?.twitter??"#"}>
                          <img src={outline1} alt="X" />
                        </Nav.Link>
                      ):(<></>)
                    }
                    {
                      socialIcons?.youtube?( 
                        <Nav.Link href={socialIcons?.youtube??"#"}>
                          <img src={youtube1} alt="youtube" />
                        </Nav.Link>
                      ):(<></>)
                    } 
                    {socialIcons?.linkedIn && (
                          <Nav.Link  to={socialIcons.linkedIn ?? "#"}>
                          <img src={linkedin1} alt="LinkedIn" />
                          </Nav.Link>
                    )}
                  </Nav>
                </Navbar>
                <div className="subscribe-wrap">
                  <div className="subscribe-head">
                    <h2>{Catalog.Strings.subscription.title}</h2>

                    <p>{Catalog.Strings.subscription.description}</p>
                  </div>
                  <div className="subscribe-body">
                    {this.state.subscriptionerror ? (
                        <Alert variant={"error"}>{this.state.subscriptionerror}</Alert>
                      ) : (
                        <></>
                      )}

                    {this.state.subscriptionsuccess ? (
                        <Alert variant={"success"}>{this.state.subscriptionsuccess}</Alert>
                      ) : (
                        <></>
                      )}
                      <Form onSubmit={this.handleSubmit} noValidate>
                        <FormField
                          placeholder={Catalog.Strings.subscription.name}
                          value={this.state.formData?.name}
                          error={this.state.errorData?.name}
                          name={"name"}
                          handle={this.handleChange}
                        />
                        <FormField
                          placeholder={Catalog.Strings.subscription.email}
                          value={this.state.formData?.email}
                          error={this.state.errorData?.email}
                          name={"email"}
                          handle={this.handleChange}
                        />
                        <Button variant="primary" type="submit">
                        {Catalog.Strings.subscription.button}
                        </Button>
                      </Form>
                    </div>
                </div>

              </div>
              <div className="col-md-5   more-news">
                {this.state.news && this.state.news.length > 0 ? (
                  <div className="more-post">
                    <span className="latest-txt">{Catalog.Strings.news.recent_more}</span>
                    <Card className="more-block">
                      <Card.Body>
                        {this.state.news.map((itm, idx) => {
                          return (
                            <div className="more-box" key={idx}>
                              <Link to={"/"+itm.slug}>
                                <div className="more-img">
                                  <img src={itm.image??noimage} alt={itm.image_alt} />
                                </div>
                                <span className="more-txt"> 
                                  <h2> {itm.title}</h2>
                                  {/* <p>The Central Board of Direct Taxes</p> */}
                                </span>
                              </Link>
                            </div>
                          );
                        })}
                      </Card.Body>
                    </Card>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-4   popular-category">
                {
                  this.state.categorys&&this.state.categorys.length>0?(
                    <>
                    <span className="popular-txt">{Catalog.Strings.news.popular_category}</span>
                    <ul>
                      {
                        this.state.categorys.filter((itm)=>{
                          return itm.post_count&&itm.post_count>10
                        }).map((itm,i)=>{
                          return (
                            <li key={i} >                         
                              <Link to={"/news/"+itm.slug}>
                                <span className="popular-content">
                                  {itm.title}
                                </span> 
                                <span className="popular-no">{itm.post_count}</span>
                              </Link>
                            </li> 
                          )
                        })
                      } 
                    </ul>
                    </>
                  ):(
                    <></>
                  )
                } 
              </div>
            </div>
          </div>
          <div className=" copyright-wrap">
            <div className="row copyright">
              <div className="col-md-6 m-auto  ">
                <p>© 2012 - {dateFormat(new Date(),"Y")} Daily Law Times Private Limited. All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
