import "./../css/NewsTags.css"; 
import NewsTagsController from "./../controllers/NewsTagsController";
import { useParams } from 'react-router-dom';

const NewsTags = () => {
  const params = useParams();
  return <NewsTagsController {...params} />;
};
  

  export default NewsTags;
  