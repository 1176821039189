import "./../css/Search.css"; 
import SearchController from "./../controllers/SearchController";
import { useParams,useSearchParams } from 'react-router-dom';

const Search = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  return <SearchController q={searchParams.get('q')} c={searchParams.get('c')} {...params} />;
};
  
export default Search;
  